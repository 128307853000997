<template>
  <div class="boss" v-padding:top="46">
    <van-nav-bar title="领导驾驶舱" fixed>
      <template #left>
        <van-icon
          name="iconfont iconfont iconqiehuan"
          size="24"
          color="#C2D3DF"
          @click="changeUser"
        />
      </template>
    </van-nav-bar>
    <panel :hidden-header="true">
      <van-tabs type="card">
        <van-tab title="事件预警">
          <ve-pie
            :data="chartData"
            :extend="chartExtend"
            :legend-visible="false"
            width="auto"
            height="250px"
            judge-width
          ></ve-pie>
          <van-cell-group>
            <van-cell>
              <span slot="title"
                ><van-tag color="#E05F38">一级事件</van-tag></span
              >
              <span slot="default" class="num"
                ><span class="number">{{ event1Early }}</span>
                <span class="precent"
                  >{{ event1EarlyPercent.toFixed(1) }}%</span
                >
              </span>
            </van-cell>
            <van-cell>
              <span slot="title"
                ><van-tag color="#44BE8E">二级事件</van-tag></span
              >
              <span slot="default" class="num"
                ><span class="number">{{ event2Early }}</span
                ><span class="precent"
                  >{{ event2EarlyPercent.toFixed(1) }}%</span
                >
              </span>
            </van-cell>
            <van-cell>
              <span slot="title"
                ><van-tag color="#378AED">三级事件</van-tag></span
              >
              <span slot="default" class="num"
                ><span class="number">{{ event3Early }}</span>
                <span class="precent">
                  {{ event3EarlyPercent.toFixed(1) }}%
                </span></span
              >
            </van-cell>
          </van-cell-group>
        </van-tab>
        <van-tab title="事件跟踪">
          <van-row class="event-processing" type="flex" justify="space-between">
            <van-col span="8" style="text-align: center">
              <van-circle
                v-model="event1ResponsePerCent"
                :rate="event1ResponsePerCent"
                :speed="100"
                :text="event1ResponsePerCentText"
                layer-color="#D3D9DD"
                size="80"
                stroke-width="80"
                color="#4190EE"
              />
              <p class="circle-title">一级事件</p>
            </van-col>
            <van-col span="8" style="text-align: center"
              ><van-circle
                v-model="event2ResponsePerCent"
                :rate="event2ResponsePerCent"
                :speed="100"
                :text="event2ResponsePerCentText"
                layer-color="#D3D9DD"
                size="80"
                stroke-width="80"
                color="#4190EE"
              />
              <p class="circle-title">二级事件</p>
            </van-col>
            <van-col span="8" style="text-align: center"
              ><van-circle
                v-model="event3ResponsePerCent"
                :rate="event3ResponsePerCent"
                :speed="100"
                :text="event3ResponsePerCentText"
                layer-color="#D3D9DD"
                size="80"
                stroke-width="80"
                color="#4190EE"
              />
              <p class="circle-title">三级事件</p>
            </van-col>
          </van-row>
        </van-tab>
        <van-tab title="处理结果">
          <van-row class="event-success" type="flex" justify="space-between">
            <van-col span="8" style="text-align: center">
              <van-circle
                v-model="event1OverPerCent"
                :rate="event1OverPerCent"
                :speed="100"
                :text="event1OverPerCentText"
                layer-color="#D3D9DD"
                size="80"
                stroke-width="80"
                color="#4EC294"
              />
              <p class="circle-title">一级事件</p>
            </van-col>
            <van-col span="8" style="text-align: center"
              ><van-circle
                v-model="event2OverPerCent"
                :rate="event2OverPerCent"
                :speed="100"
                :text="event2OverPerCentText"
                layer-color="#D3D9DD"
                size="80"
                stroke-width="80"
                color="#4EC294"
              />
              <p class="circle-title">二级事件</p>
            </van-col>
            <van-col span="8" style="text-align: center"
              ><van-circle
                v-model="event3OverPerCent"
                :rate="event3OverPerCent"
                :speed="100"
                :text="event3OverPerCentText"
                layer-color="#D3D9DD"
                size="80"
                stroke-width="80"
                color="#4EC294"
              />
              <p class="circle-title">三级事件</p>
            </van-col>
          </van-row>
        </van-tab>
        <div class="type-select">
          <span
            class="type-button"
            @click="warningsType('week')"
            :class="warningsSelected == 'week' ? 'type-button-active' : ''"
          >
            <img :src="require('@/assets/img/week.png')" />
            <span class="type-name" v-if="warningsSelected == 'week'"
              >| 周</span
            >
          </span>
          <span
            class="type-button"
            @click="warningsType('month')"
            :class="warningsSelected == 'month' ? 'type-button-active' : ''"
          >
            <img :src="require('@/assets/img/month.png')" />
            <span class="type-name" v-if="warningsSelected == 'month'"
              >| 月</span
            >
          </span>
          <span
            class="type-button"
            @click="warningsType('year')"
            :class="warningsSelected == 'year' ? 'type-button-active' : ''"
          >
            <img :src="require('@/assets/img/year.png')" />
            <span class="type-name" v-if="warningsSelected == 'year'"
              >| 年</span
            >
          </span>
        </div>
      </van-tabs>
    </panel>
    <!-- <panel title="远程巡检">
      <div slot="extra">
        <p class="panel-extra">
          巡检总数：<span class="panel-extra-main">{{ inspectionCount }}</span
          >次
        </p>
      </div>
      <slot>
        <ve-histogram
          :data="chartDataRemote"
          :settings="settings"
          :legend-visible="false"
          :extend="labelStyle"
          height="300px"
          judge-width
        ></ve-histogram>
      </slot>
    </panel> -->
    <!-- <panel title="推送统计">
      <div slot="extra">
        <p class="panel-extra">
          推送总数：<span class="panel-extra-main">{{ pushTotal }}</span
          >次
        </p>
      </div>
      <slot>
        <van-row
          class="event-success"
          type="flex"
          justify="space-between"
          gutter="8"
        >
          <van-col span="8" style="text-align:center">
            <div class="push-box">
              <p class="push-box-value">{{ enforcePush }}</p>
              <p class="push-box-title">执法人员</p>
            </div>
          </van-col>
          <van-col span="8" style="text-align:center">
            <div class="push-box">
              <p class="push-box-value">{{ operationPush }}</p>
              <p class="push-box-title">运维商</p>
            </div>
          </van-col>
          <van-col span="8" style="text-align:center">
            <div class="push-box">
              <p class="push-box-value">{{ enterprisePush }}</p>
              <p class="push-box-title">企业</p>
            </div>
          </van-col>
        </van-row>
        <div class="type-select">
          <span
            class="type-button"
            @click="warningType('week')"
            :class="warningSelected == 'week' ? 'type-button-active' : ''"
          >
            <img :src="require('@/assets/img/week.png')" />
            <span class="type-name" v-if="warningSelected == 'week'">| 周</span>
          </span>
          <span
            class="type-button"
            @click="warningType('month')"
            :class="warningSelected == 'month' ? 'type-button-active' : ''"
          >
            <img :src="require('@/assets/img/month.png')" />
            <span class="type-name" v-if="warningSelected == 'month'"
              >| 月</span
            >
          </span>
          <span
            class="type-button"
            @click="warningType('year')"
            :class="warningSelected == 'year' ? 'type-button-active' : ''"
          >
            <img :src="require('@/assets/img/year.png')" />
            <span class="type-name" v-if="warningSelected == 'year'">| 年</span>
          </span>
        </div>
      </slot>
    </panel> -->
    <panel title="评分排行">
      <div slot="extra">
        <div class="rank-tab">
          <van-tabs type="card" v-model="rankTab">
            <van-tab
              title="企业"
              name="enterprise"
              @click="rankTab == 'enterprise'"
              :class="rankTab == 'enterprise' ? 'selected' : ''"
            ></van-tab>
            <van-tab
              title="运维商"
              name="operation"
              @click="rankTab == 'operation'"
              :class="rankTab == 'operation' ? 'selected' : ''"
            ></van-tab>
          </van-tabs>
        </div>
      </div>
      <slot>
        <div class="rank-list">
          <template v-if="rankTab == 'enterprise'">
            <div class="rank-list-title">
              <div class="title-item" style="flex-basis: 50px">排行</div>
              <div class="title-item" style="flex-basis: 250px">企业</div>
              <div class="title-item" style="flex-basis: 50px">评分</div>
            </div>
            <div
              class="rank-list-value"
              v-for="(item, index) in companyList"
              :key="index"
            >
              <div class="value-item" style="flex-basis: 50px">
                {{ index + 1 }}
              </div>
              <div class="value-item" style="flex-basis: 250px">
                {{ item.enterpiseName }}
              </div>
              <div class="value-item" style="flex-basis: 50px">
                {{ item.score > 0 ? item.score : 0 }}
              </div>
            </div>
          </template>
          <template v-if="rankTab == 'operation'">
            <div class="rank-list-title">
              <div class="title-item" style="flex-basis: 50px">排行</div>
              <div class="title-item" style="flex-basis: 250px">运维商</div>
              <div class="title-item" style="flex-basis: 50px">评分</div>
            </div>
            <div
              class="rank-list-value"
              v-for="(item, index) in operationList"
              :key="index"
            >
              <div class="value-item" style="flex-basis: 50px">
                {{ index + 1 }}
              </div>
              <div class="value-item" style="flex-basis: 250px">
                {{ item.enterpiseName }}
              </div>
              <div class="value-item" style="flex-basis: 50px">
                {{ item.score > 0 ? item.score : 0 }}
              </div>
            </div>
          </template>
        </div>
      </slot>
    </panel>
  </div>
</template>

<script>
import panel from "@/components/common/panel.vue"; // 公共面板

export default {
  components: { panel },
  data() {
    return {
      event1Early: 0, // 一级事件预警
      event2Early: 0, // 二级事件预警
      event3Early: 0, // 三级事件预警,
      event1EarlyPercent: 0, // 一级事件预警
      event2EarlyPercent: 0, // 二级事件预警
      event3EarlyPercent: 0, // 三级事件预警
      event1ResponsePerCent: 0, // 一级事件响应百分比
      event2ResponsePerCent: 0, // 二级事件响应百分比
      event3ResponsePerCent: 0, // 三级事件响应百分比
      event1OverPerCent: 0, // 一级事件完结百分比
      event2OverPerCent: 0, // 二级事件完结百分比
      event3OverPerCent: 0, // 三级事件完结百分比
      companyList: [], // 企业列表
      operationList: [], // 运维列表
      pushTotal: 0, // 推送总数
      enforcePush: 0, // 执法推送
      operationPush: 0, // 运维推送
      enterprisePush: 0, // 企业推送
      inspectionCount: 0, // 巡检总数
      rankTab: "enterprise",
      chartData: {
        columns: ["type", "num"],
        rows: [
          { type: "一级事件", num: 0 },
          { type: "二级事件", num: 0 },
          { type: "三级事件", num: 0 },
        ],
      },
      chartExtend: {
        series: {
          top: -80,
          height: 200,
          radius: ["55%", "70%"],
        },
      },
      warningSelected: "week",
      warningsSelected: "week",
      selected: "",
      settings: {
        labelMap: {
          month: "月份",
          totalPrice: "巡查数量",
        },
      },
      chartDataRemote: {
        columns: ["month", "totalPrice"],
        rows: [],
      },
      labelStyle: {
        xAxis: {
          show: true,
          axisLabel: {
            interval: 0,
          },
        },
      },
    };
  },
  watch: {
    // warningType() {
    //   this.onPushTypeChange();
    // },
    warningsType() {
      this.onEventStatisticTypeChange();
    },
    // rankTab() {
    //   this.onSearch();
    // }
  },
  mounted() {
    // this.onPushTypeChange();
    this.onEventStatisticTypeChange();
    // this.getInspectStatistical();
    this.getEnterpriseRank();
    this.getMaintainRank();
  },
  methods: {
    // 推送时间类型改变
    // warningType(type) {
    //   this.warningSelected = type;
    //   this.onPushTypeChange();
    // },
    // 事件预警时间类型改变
    warningsType(type) {
      this.warningsSelected = type;
      this.onEventStatisticTypeChange();
    },
    // 推送类型改变事件
    // onPushTypeChange() {
    //   let data = {};
    //   if (this.warningSelected == "week") {
    //     //周
    //     data = {
    //       beginTime:
    //         this.$moment()
    //           .startOf("week")
    //           .format("YYYY-MM-DD") + " 00:00:00",
    //       endTime:
    //         this.$moment()
    //           .endOf("week")
    //           .format("YYYY-MM-DD") + " 23:59:59"
    //     };
    //   } else if (this.warningSelected == "month") {
    //     data = {
    //       beginTime:
    //         this.$moment()
    //           .startOf("month")
    //           .format("YYYY-MM-DD") + " 00:00:00",
    //       endTime:
    //         this.$moment()
    //           .endOf("month")
    //           .format("YYYY-MM-DD") + " 23:59:59"
    //     };
    //   } else if (this.warningSelected == "year") {
    //     data = {
    //       beginTime:
    //         this.$moment()
    //           .startOf("year")
    //           .format("YYYY-MM-DD") + " 00:00:00",
    //       endTime:
    //         this.$moment()
    //           .endOf("year")
    //           .format("YYYY-MM-DD") + " 23:59:59"
    //     };
    //   }
    //   this.$api.boss.getPushStatistics(data).then(res => {
    //     if (res.data.state == 0) {
    //       let _data = res.data.data;
    //       this.pushTotal = 0;
    //       this.enforcePush = 0;
    //       this.operationPush = 0;
    //       this.enterprisePush = 0;
    //       if (_data) {
    //         this.pushTotal = _data.total;
    //         this.enforcePush = _data.enforcePush;
    //         this.operationPush = _data.operationPush;
    //         this.enterprisePush = _data.enterprisePush;
    //       }
    //     }
    //   });
    // },
    // 事件预警类型改变事件
    onEventStatisticTypeChange() {
      let data = {};
      if (this.warningsSelected == "week") {
        // 周
        data = {
          beginTime:
            this.$moment().startOf("week").format("YYYY-MM-DD") + " 00:00:00",
          endTime:
            this.$moment().endOf("week").format("YYYY-MM-DD") + " 23:59:59",
        };
      } else if (this.warningsSelected == "month") {
        data = {
          beginTime:
            this.$moment().startOf("month").format("YYYY-MM-DD") + " 00:00:00",
          endTime:
            this.$moment().endOf("month").format("YYYY-MM-DD") + " 23:59:59",
        };
      } else if (this.warningsSelected == "year") {
        data = {
          beginTime:
            this.$moment().startOf("year").format("YYYY-MM-DD") + " 00:00:00",
          endTime:
            this.$moment().endOf("year").format("YYYY-MM-DD") + " 23:59:59",
        };
      }
      this.$api.boss.getEventStatistics(data).then((res) => {
        if (res.data.state == 0) {
          this.event1Early = res.data.data.event1Early;
          this.event2Early = res.data.data.event2Early;
          this.event3Early = res.data.data.event3Early;
          this.chartData.rows[0].num = res.data.data.event1Early;
          this.chartData.rows[1].num = res.data.data.event2Early;
          this.chartData.rows[2].num = res.data.data.event3Early;
          const sum =
            res.data.data.event1Early +
            res.data.data.event2Early +
            res.data.data.event3Early;
          this.event1EarlyPercent = (res.data.data.event1Early / sum) * 100;
          this.event2EarlyPercent = (res.data.data.event2Early / sum) * 100;
          this.event3EarlyPercent = (res.data.data.event3Early / sum) * 100;
          this.event1ResponsePerCent = res.data.data.event1ResponsePerCent;
          this.event2ResponsePerCent = res.data.data.event2ResponsePerCent;
          this.event3ResponsePerCent = res.data.data.event3ResponsePerCent;
          this.event1OverPerCent = res.data.data.event1OverPerCent;
          this.event2OverPerCent = res.data.data.event2OverPerCent;
          this.event3OverPerCent = res.data.data.event3OverPerCent;
        }
      });
    },
    // 获取远程巡查统计数据
    // getInspectStatistical() {
    //   this.$api.boss.getInspectStatistical().then(res => {
    //     if (res.data.state == 0) {
    //       let _data = res.data.data;
    //       let tempCount = 0;
    //       let temp = [
    //         { month: "1月", totalPrice: 0 },
    //         { month: "2月", totalPrice: 0 },
    //         { month: "3月", totalPrice: 0 },
    //         { month: "4月", totalPrice: 0 },
    //         { month: "5月", totalPrice: 0 },
    //         { month: "6月", totalPrice: 0 },
    //         { month: "7月", totalPrice: 0 },
    //         { month: "8月", totalPrice: 0 },
    //         { month: "9月", totalPrice: 0 },
    //         { month: "10月", totalPrice: 0 },
    //         { month: "11月", totalPrice: 0 },
    //         { month: "12月", totalPrice: 0 }
    //       ];
    //       if (_data.length > 0) {
    //         _data.forEach(element => {
    //           temp[element.day - 1].totalPrice = element.totalPrice;
    //           tempCount += element.totalPrice;
    //         });
    //       }
    //       this.chartDataRemote.rows = temp;
    //       this.inspectionCount = tempCount;
    //     }
    //   });
    // },
    // 企业排行查询
    onEnterpriseRank() {
      this.getEnterpriseRank();
    },
    // 运维排行查询
    onMaintainRank() {
      this.getMaintainRank();
    },
    // 获取企业评分排行
    getEnterpriseRank() {
      const data = {
        type: 2,
        sort: 0,
      };
      this.companyList = [];
      this.$api.boss.getEnterpriseRank(data).then((res) => {
        if (res.data.state == 0) {
          const _data = res.data.data;
          this.companyList = _data;
        }
      });
    },
    // 获取运维商评分排行
    getMaintainRank() {
      const data = {
        type: 1,
        sort: 0,
      };
      this.operationList = [];
      this.$api.boss.getEnterpriseRank(data).then((res) => {
        if (res.data.state == 0) {
          const _data = res.data.data;
          this.operationList = _data;
        }
      });
    },
    onSearch() {
      if (this.rankTab == "enterprise") {
        this.companyList = [];
        this.onEnterpriseRank();
      }
      if (this.rankTab == "operation") {
        this.operationList = [];
        this.onMaintainRank();
      }
    },
  },
  computed: {
    event1ResponsePerCentText() {
      return Number(this.event1ResponsePerCent).toFixed(0) + "%";
    },
    event2ResponsePerCentText() {
      return Number(this.event2ResponsePerCent).toFixed(0) + "%";
    },
    event3ResponsePerCentText() {
      return Number(this.event3ResponsePerCent).toFixed(0) + "%";
    },
    event1OverPerCentText() {
      return Number(this.event1OverPerCent).toFixed(0) + "%";
    },
    event2OverPerCentText() {
      return Number(this.event2OverPerCent).toFixed(0) + "%";
    },
    event3OverPerCentText() {
      return Number(this.event3OverPerCent).toFixed(0) + "%";
    },
  },
};
</script>

<style></style>
